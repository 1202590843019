<template>
    <div>
        <infoHeader :content_name="'新增升降机设备'" />

        <h4>输入需要新增的升降机设备</h4>

        <el-form
            ref="formData"
            :model="formData"
            label-width="160px"
            label-position="left"
        >
            <el-form-item label="升降机生产商">
                <el-select
                    v-model="formData.lifter_producer_id"
                    placeholder="请选择"
                    @change="handleTypeChange"
                    clearable
                >
                    <el-option
                        v-for="item in lifterProducers"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="生产商产品" v-if="formData.lifter_producer_id">
                <el-select
                    v-model="formData.lifter_product_id"
                    placeholder="请选择"
                    clearable
                >
                    <el-option
                        v-for="item in lifterProductList"
                        :key="item.id"
                        :label="item.lifter_name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="升降机安装地址">
                <el-input v-model="formData.Installation_address" />
            </el-form-item>
            <el-form-item label="升降机安装层高">
                <el-input v-model.number="formData.floor" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submit"
                    >确认无误，立即创建</el-button
                >
            </el-form-item>
        </el-form>

        <el-divider />
    </div>
</template>

<script>
import {
    lifter_manufacturer_manage,
    lifter_manage_create,
    lifter_product_query
} from '@/api/manage/operation/lifter_manage/lifter_manage.js';

export default {
    name: 'start',
    data() {
        return {
            formData: {
                lifter_producer_id: null,
                Installation_address: '',
                floor: '',
                lifter_product_id: null
            },
            lifterProducers: [],
            lifterProductList: []
        };
    },
    created() {
        this.loadLifterProducers();
    },
    methods: {
        submit() {
            
            lifter_manage_create(this.formData).then(res => {
                if (res.code === 200) {
                    this.$message.success('成功创建升降机设备');
                    this.$router.push({ name: 'lifter_Manage' });
                } else {
                    this.$message.info(res.msg);
                }
            }).catch(() => {
                this.$message.error('创建升降机设备失败');
            });
        },
        loadLifterProducers() {
            lifter_manufacturer_manage({}).then(res => {
                if (res.code === 200) {
                    this.lifterProducers = res.data;
                } else {
                    this.$message.info(res.msg);
                }
            }).catch(() => {
                this.$message.error('加载生产商失败');
            });
        },
        handleTypeChange(selectedItemId) {
            this.getManufacturerProduct(selectedItemId);
        },
        getManufacturerProduct(producerId) {
            lifter_product_query({ producer_id: producerId }).then(res => {
                if (res.code === 200) {
                    this.lifterProductList = res.data;
                } else {
                    this.$message.info(res.msg);
                }
            }).catch(() => {
                this.$message.error('获取生产商产品失败');
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
